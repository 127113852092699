.title{
    font-size: 24px;
    text-align: center;
    margin: 0px;
}
.descripction{
    font-size: 16px;
    text-align: center;
}
.btn{
    width: 100% !important;
}