.nav {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  top: 0;
  width: 100vw;
  align-items: center;
  z-index: 10;
  background-color: white;
  /* box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px; */
}
.img {
  width: 80px;
  height: auto;
}
.imageContainer {
  width: 100%;
}
